<template>
  <div />
</template>

<script setup lang="ts">
import { auth, db } from '@/config/firebase'
import type { Unsubscribe } from 'firebase/auth'
import {
  collection,
  limit,
  onSnapshot,
  query,
  where,
  type Unsubscribe as UnsubscribeFirestore
} from 'firebase/firestore'
import { onUnmounted, ref } from 'vue'

const vLibrasDiv = ref<HTMLDivElement | null>(null)
const vLibrasScript = ref<HTMLScriptElement | null>(null)
const authSubscribe = ref<Unsubscribe>()
const firestoreSubscribe = ref<UnsubscribeFirestore>()

injectVLibras()
startListenUser()

onUnmounted(() => {
  vLibrasDiv.value?.remove()
  vLibrasScript.value?.remove()
  authSubscribe.value?.()
  firestoreSubscribe.value?.()
})

function startListenUser() {
  authSubscribe.value = auth.onAuthStateChanged((user) => {
    if (!user) return
    const ref = collection(db, 'users')
    const q = query(ref, where('email', '==', user.email), limit(1))
    firestoreSubscribe.value = onSnapshot(q, (snapshot) => {
      if (snapshot.empty) {
        return
      }
      snapshot?.docs?.[0]?.get('is_show_vlibras')
        ? vLibrasDiv.value?.classList.remove('hidden')
        : vLibrasDiv.value?.classList.add('hidden')
    })
  })
}

function injectVLibras() {
  vLibrasDiv.value = document.createElement('div')
  vLibrasDiv.value?.classList.add('hidden')
  vLibrasDiv.value.innerHTML += `
          <div vw class="enabled">
            <div vw-access-button class="active"></div>
            <div vw-plugin-wrapper>
              <div class="vw-plugin-top-wrapper"></div>
            </div>
          </div>
        `
  document.body.appendChild(vLibrasDiv.value)

  vLibrasScript.value = document.createElement('script')
  vLibrasScript.value.src = 'https://vlibras.gov.br/app/vlibras-plugin.js'
  vLibrasScript.value.async = true
  document.body.appendChild(vLibrasScript.value)
  vLibrasScript.value.onload = () => {
    new (window as any).VLibras.Widget('https://vlibras.gov.br/app')
  }
}
</script>
