<template>
  <v-app>
    <RouterView />

    <!-- register base components here -->
    <VLibraComponent />
    <BaseLoading />
    <BaseSnackBar />
  </v-app>
</template>

<script lang="ts" setup>
import BaseLoading from './components/base/BaseLoading.vue'
import BaseSnackBar from './components/base/BaseSnackBar.vue'
import { useConnectionStatusStore } from './stores/connection'
import VLibraComponent from '@/components/vlibras/VLibraComponent.vue'

const connectionStore = useConnectionStatusStore()

window.addEventListener('offline', () => connectionStore.setOffline())
window.addEventListener('online', () => connectionStore.setOnline())
</script>
