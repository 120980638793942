<template>
  <v-spacer v-if="!propsReactive.inlineValue.value" :class="textAreaStyled">
    <textarea
      :id="propsReactive.id?.value as string"
      :disabled="disabled"
      :value="propsReactive.modelValue.value"
    />
  </v-spacer>
  <v-spacer v-else class="px-4">
    <div
      :id="propsReactive.id?.value as string"
      :style="{ minHeight: `${propsReactive.sizeHeight?.value}px` }"
      class="border-2 border-[rgb(229, 231, 235)] border-solid rounded-md mt-5 p-2"
    ></div>
  </v-spacer>
</template>

<script lang="ts" setup>
import { onMounted, toRefs } from 'vue'
import { imagesUploadHandler } from '@/config/tinymce'
import tinymce, { Editor } from 'tinymce'

const props = defineProps({
  id: {
    type: [String],
    default: ''
  },
  bullistValue: {
    type: String,
    default: 'bullist'
  },
  numlistValue: {
    type: String,
    default: 'numlist'
  },
  modelValue: {
    type: String,
    default: ''
  },
  inlineValue: {
    type: Boolean,
    default: false
  },
  menubarValue: {
    type: Boolean,
    default: true
  },
  sizeHeight: {
    type: Number,
    default: 400
  },
  placeholderValue: {
    type: String,
    default: 'Descrição'
  },
  disabled: {
    type: Boolean,
    default: false
  },
  textAreaStyled: {
    type: String,
    default: 'px-4 mt-5'
  },
  toolbarValue: {
    type: String,
    default: ''
  }
})
const propsReactive = toRefs(props)

const emit = defineEmits(['update:modelValue'])

onMounted(async () => {
  initTinymce()
})

function initTinymce() {
  tinymce.init({
    selector: `#${propsReactive.id?.value}`,
    base_url: '/tinymce',
    readonly: propsReactive.disabled?.value,
    toolbar: propsReactive.toolbarValue?.value
      ? propsReactive.toolbarValue.value
      : 'undo redo | lists editimage image media |' +
        'bold italic underline | forecolor backcolor |' +
        'alignleft aligncenter alignright alignjustify |' +
        `${propsReactive.bullistValue?.value} ${propsReactive.numlistValue?.value} outdent indent |` +
        'eqneditor a11ycheck',
    plugins: `lists link image table code help wordcount media eqneditor a11ychecker`,
    contextmenu: 'link useBrowserSpellcheck',
    external_plugins: {
      eqneditor: '/tinymce/plugins/eqneditor.js',
      a11ychecker: '/tinymce/plugins/a11ychecker/a11ychecker.js'
    },
    min_height: propsReactive.sizeHeight?.value,
    inline: propsReactive.inlineValue?.value,
    menubar: propsReactive.menubarValue?.value,
    language: 'pt_BR',
    placeholder: propsReactive.placeholderValue?.value,
    a11ychecker_level: 'aaa',
    images_upload_handler: imagesUploadHandler,
    images_reuse_filename: true,
    browser_spellcheck: true,
    promotion: false,
    license_key: 'gpl',
    setup: (editor: Editor) => {
      editor.ui.registry.addMenuItem('useBrowserSpellcheck', {
        text: 'Usar corretor ortográfico do navegador',
        onAction: function () {
          editor.notificationManager.open({
            text:
              'Segure Ctrl e clique com o botão direito na palavra mal ' +
              'escrita para acessar o corretor ortográfico.',
            type: 'info',
            timeout: 5000
          })
        }
      })

      editor.on('NodeChange Change Undo Redo keyup', () => {
        emit('update:modelValue', editor.getContent())
      })

      editor.on('init', () => {
        editor.setContent(propsReactive.modelValue?.value)
      })
    }
  })
}
</script>

<style>
/*
  Hide branding and notifications
*/
.tox-statusbar__branding {
  display: none !important;
}

.tox-notification {
  display: none !important;
}
</style>
